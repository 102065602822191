import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
class CreateCertTjSc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      docEmt: "CERTIDÃO",
      nomeServentia: "",
      nome: "",
      pessoa: "",
      profissao: "",
      nacionalidade: "",
      estadoCivil: "",
      sexo: "",
      docTipo: "",
      docNo: "",
      tipo: "",
      logradouro: "",
      numero: "",
      bairro: "",
      cidadeUf: "",
      cep: "",
      tipoTel: "",
      telDDD: "",
      telNmr: "",
      valor: "",
      emolumento: "",
      iss: "",
      dataHoraFinalizacao: "",
      dataHoraRecebimento: "",
      dataSolicitacao: "",
      nomeLivro: "",
      noLivro: "",
      paginaInicial: "",
      paginaFinal: "",
      dataRegistro: "",
      noAssento: "",
      obs: "",
      dados: [],
    };
  }

  componentDidMount() {
    document.title = "Emitir Certidão TJSC ";
    fetch(
      `${apiUrl}/listar-cartorios/` + window.location.pathname.split("/")[1],
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    var dados = this.state.dados;
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Emitir Certidão TJSC{" "}
            </h2>
          </div>
        </header>

        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3>Emissão de certidão:</h3>
                  </div>
                  <div class="card-body">
                    <div className="card-body">
                      <div className="col  mb-2 ">
                        <label>Titular da certidão:</label>
                        <input
                          type="text"
                          name="titular"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.dados.titular}
                          className="form-control form-control-sm"
                        />
                      </div>

                      <div className="form-group">
                        <label>Nome da Serventia:</label>
                        <select
                          class="form-control form-control-sm"
                          onChange={(e) => {
                            if (e.target.value == "") return;
                            var item =
                              this.state.data.carts[e.target.selectedIndex - 1];
                            console.log(item);
                            this.setState((prevState) => ({
                              dados: {
                                ...prevState.dados, // Mantém os outros campos do objeto `dados`
                                ["nomeServentia"]: item.id,
                                ["Cds"]:
                                  item.cds != undefined ? item.cds : "R$0,00", // Atualiza apenas o campo correspondente
                              },
                            }));
                          }}
                        >
                          <option value="">Selecione uma opção</option>
                          {this.state.data.carts.map((item, index) => {
                            return (
                              <>
                                <option value={item.id}>{item.nome}</option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <div class="form-group mt-2">
                        <label>Tipo do ato:</label>
                        <select
                          class="form-control form-control-sm"
                          name="tipo_ato"
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option>Selecione uma opção</option>
                          {[
                            { value: "CERTIDÃO", label: "CERTIDÃO" },
                            {
                              value: "CERTIDÃO DE NASCIMENTO",
                              label: "CERTIDÃO DE NASCIMENTO",
                            },
                            {
                              value: "CERTIDÃO DE CASAMENTO",
                              label: "CERTIDÃO DE CASAMENTO",
                            },
                            {
                              value: "CERTIDÃO DE ÓBITO",
                              label: "CERTIDÃO DE ÓBITO",
                            },
                            {
                              value: "PROCURAÇÃO PÚBLICA",
                              label: "PROCURAÇÃO PÚBLICA",
                            },
                            {
                              value: "ESCRITURA PÚBLICA",
                              label: "ESCRITURA PÚBLICA",
                            },
                            {
                              value: "NASCIMENTO INTEIRO TEOR",
                              label: "NASCIMENTO INTEIRO TEOR",
                            },
                            {
                              value: "CASAMENTO BREVE RELATO",
                              label: "CASAMENTO BREVE RELATO",
                            },
                            {
                              value: "CASAMENTO INTEIRO TEOR",
                              label: "CASAMENTO INTEIRO TEOR",
                            },
                            {
                              value: "ÓBITO BREVE RELATO",
                              label: "ÓBITO BREVE RELATO",
                            },
                            {
                              value: "ÓBITO INTEIRO TEOR",
                              label: "ÓBITO INTEIRO TEOR",
                            },
                            { value: "DECLARAÇÃO", label: "DECLARAÇÃO" },
                            { value: "PROCURAÇÃO", label: "PROCURAÇÃO" },
                          ].map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="row d-flex align-items-center">
                        <div className="col mt-3 ">
                          <label>Tipo de Cobrança</label>
                          <input
                            type="text"
                            name="nome"
                            disabled
                            value="Normal"
                            className="form-control form-control-sm"
                          />
                        </div>

                        <div className="col mt-3 ">
                          <label>Emolumento principal (ato):</label>
                          <input
                            type="text"
                            name="nome"
                            disabled
                            value="R$ 50,00"
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mt-3 ">
                          <label>FRJ:</label>
                          <input
                            type="text"
                            disabled
                            value="R$ 11,36"
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mt-3 ">
                          <label>ISS:</label>
                          <input
                            type="text"
                            value={dados.Cds}
                            disabled
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mt-3 ">
                          <label>Valor:</label>
                          <input
                            type="text"
                            value={(() => {
                              const frj = dados.frj
                                ? parseFloat(
                                    dados.frj
                                      .replace("R$", "")
                                      .replace(",", ".")
                                  )
                                : 0;

                              const Cds = dados.Cds
                                ? parseFloat(
                                    dados.Cds.replace("R$", "").replace(
                                      ",",
                                      "."
                                    )
                                  )
                                : 0;

                              return (50 + 11.36 + Cds).toLocaleString(
                                "pt-BR",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              ); // Formata o total com 2 casas decimais
                            })()}
                            disabled
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>

                      <div className="col mt-3 mb-3">
                        <label>Data e hora da finalização do ato:</label>
                        <input
                          type="datetime-local"
                          name="dateHfa"
                          onChange={(e) => {
                            const dataInicial = new Date(e.target.value);

                            // Variável com +1h e +7min
                            const dataCom1h7min = new Date(dataInicial);
                            dataCom1h7min.setHours(
                              dataCom1h7min.getHours() + 2
                            );
                            dataCom1h7min.setMinutes(
                              dataCom1h7min.getMinutes() +
                                Math.floor(Math.random() * 59) +
                                1
                            );
                            const formattedDate1 = dataCom1h7min
                              .toLocaleString("sv-SE", { hour12: false })
                              .slice(0, 16);

                            // Variável com +2h e +7min
                            const dataCom2h7min = new Date(dataInicial);
                            dataCom2h7min.setHours(
                              dataCom2h7min.getHours() + 2
                            );
                            dataCom2h7min.setMinutes(
                              dataCom2h7min.getMinutes() +
                                Math.floor(Math.random() * 59) +
                                1
                            );
                            const formattedDate2 = dataCom2h7min
                              .toLocaleString("sv-SE", { hour12: false })
                              .slice(0, 16);

                            this.setState((prevState) => ({
                              dados: {
                                ...prevState.dados, // Mantém os outros campos do objeto `dados`
                                ["dateHfa"]: e.target.value, // Atualiza apenas o campo correspondente
                                ["dateRdp"]: formattedDate1,
                                ["dateQas"]: formattedDate2,
                              },
                            }));
                          }}
                          className="form-control form-control-sm"
                        />
                      </div>

                      <div className="col mt-3 mb-3">
                        <label>
                          Data e hora do recebimento do ato pelo TJSC:{" "}
                        </label>
                        <input
                          type="datetime-local"
                          name="dateRdp"
                          value={dados.dateRdp}
                          onChange={(e) => {
                            this.setState((prevState) => ({
                              dados: {
                                ...prevState.dados, // Mantém os outros campos do objeto `dados`

                                ["dateRdp"]: e.target.value,
                              },
                            }));
                          }}
                          className="form-control form-control-sm"
                        />
                      </div>

                      <div className="col mt-3 mb-3">
                        <label>Data em que o ato foi solicitado:</label>

                        <input
                          type="date"
                          value={
                            dados.dateQas != null && dados.dateQas.split(" ")[0]
                          }
                          onChange={(e) => {
                            this.setState((prevState) => ({
                              dados: {
                                ...prevState.dados, // Mantém os outros campos do objeto `dados`

                                ["dateQas"]: e.target.value,
                              },
                            }));
                          }}
                          name="dateQas"
                          className="form-control form-control-sm"
                        />
                      </div>

                      <div className="col mt-3">
                        <label>Ato Protocolar :</label>
                        <input
                          type="text"
                          disabled
                          value="Campo 'atoProtocolar' ausente. "
                          name="nome"
                          className="form-control form-control-sm"
                        />
                      </div>

                      <div className="mt-3">
                        <label>
                          <strong>Solicitante:</strong>
                        </label>
                        <select
                          class="form-control form-control-sm"
                          onChange={(e) => {
                            if (e.target.value == "") return;
                            var item =
                              this.state.data.slc[e.target.selectedIndex - 1];
                            console.log(item);
                            this.setState((prevState) => ({
                              dados: {
                                ...prevState.dados, // Mantém os outros campos do objeto `dados`
                                ["nome"]: item.nome,
                                ["dataNascimento"]: item.dataNascimento,
                                ["profissao"]: item.profissao,
                                ["pessoa"]: item.pessoa,
                                ["estadoCivil"]: item.estadoCivil,
                                ["nacionalidade"]: item.nacionalidade,
                                ["sexo"]: item.sexo,
                                ["tipoDocumento"]: item.tipoDocumento,
                                ["numeroDocumento"]: item.numeroDocumento,
                                ["tipoEndereco"]: item.tipoEndereco,
                                ["logradouro"]: item.logradouro,
                                ["numero"]: item.numero,
                                ["bairro"]: item.bairro,
                                ["Rg"]: item.Rg,
                                ["Telefone"]: item.Telefone,
                                ["cidade"]: item.cidade,
                                ["cep"]: item.cep,
                              },
                            }));
                          }}
                        >
                          <option value="">Selecione uma opção</option>
                          {this.state.data.slc.map((item, index) => {
                            return (
                              <>
                                <option value={item.id}>{item.nome}</option>
                              </>
                            );
                          })}
                        </select>
                      </div>

                      <div className="card mt-3">
                        <div className="card-header">Selo Digital</div>
                        <div className="card-body">
                          <div className="col mb-3">
                            <label>Tipo:</label>
                            <input
                              type="text"
                              disabled
                              value="Selo Normal"
                              className="form-control form-control-sm"
                            />
                          </div>
                          <div className="col mb-3">
                            <label>Selo Nº:</label>
                            <input
                              type="text"
                              disabled
                              value="Gerado Automaticamente"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card mt-3">
                        <div className="card-header">
                          Informações Complementares
                        </div>
                        <div className="card-body">
                          <div className="col mb-3">
                            <label>Retificador:</label>
                            <input
                              type="text"
                              disabled
                              value="Não"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card mt-3">
                        <div className="card-header">Certidão</div>
                        <div className="card-body">
                          <div className="col mb-3">
                            <label>Número da via:</label>
                            <input
                              type="text"
                              disabled
                              value="2"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mt-3 mb-3">
                        <label>Observações:</label>
                        <textarea
                          type="text"
                          name="obs"
                          value={dados.obs}
                          onChange={this.handleChange}
                          className="form-control form-control-sm"
                        />
                      </div>
                      <div className="card mt-3">
                        <div className="card-header">Registro</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col mb-3">
                              <label>Código do Livro:</label>
                              <input
                                type="text"
                                name="cod_livro"
                                value={dados.cod_livro}
                                onChange={this.handleChange}
                                className="form-control form-control-sm"
                              />
                            </div>
                            <div className="col mb-3">
                              <label>Nome do Livro:</label>
                              <input
                                type="text"
                                name="nome_livro"
                                value={dados.nome_livro}
                                onChange={this.handleChange}
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col mb-3">
                              <label>Nº do Livro::</label>
                              <input
                                type="text"
                                name="num_livro"
                                value={dados.num_livro}
                                onChange={this.handleChange}
                                className="form-control form-control-sm"
                              />
                            </div>
                            <div className="col mb-3">
                              <label>Página Inicial:</label>
                              <input
                                type="text"
                                name="pag_inicial"
                                value={dados.pag_inicial}
                                onChange={this.handleChange}
                                className="form-control form-control-sm"
                              />
                            </div>
                            <div className="col mb-3">
                              <label>Página Final:</label>
                              <input
                                type="text"
                                name="pag_final"
                                value={dados.pag_final}
                                onChange={this.handleChange}
                                className="form-control form-control-sm"
                              />
                            </div>
                            <div className="col mb-3">
                              <label>Data do Registro:</label>
                              <input
                                type="text"
                                name="data_registro"
                                value={dados.data_registro}
                                onChange={this.handleChange}
                                className="form-control form-control-sm"
                              />
                            </div>
                            <div className="col mb-3">
                              <label>Nº Assento:</label>
                              <input
                                type="text"
                                name="num_assento"
                                value={dados.num_assento}
                                onChange={this.handleChange}
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-danger float-left ml-2"
                      onClick={() => {
                        this.props.navigate(-1);
                      }}
                    >
                      Voltar
                    </button>

                    <button
                      type="submit"
                      class="btn btn-primary float-right ml-2"
                      onClick={() => {
                        var dados = this.state.dados;
                        console.log(dados);

                        if (dados.nomeServentia == undefined) {
                          toast.warn("Selecione o nome da Serventia!");
                          return false;
                        }
                        if (dados.nomeServentia == "") {
                          toast.warn("Selecione o nome da Serventia!");
                          return false;
                        }

                        if (dados.nome == undefined || dados.nome == "") {
                          toast.warn("Selecione o solicitante");
                          return;
                        }

                        fetch(
                          `${apiUrl}/${
                            window.location.pathname.split("/")[1]
                          }/criar-certificado/`,
                          {
                            method: "POST",
                            credentials: "include",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization:
                                "Bearer " + Cookies.get("auth_token"),
                            },
                            body: JSON.stringify(dados),
                          }
                        ).then((response) => {
                          if (response.status == 200) {
                            toast.success("Certificado Criado com sucesso!");

                            response.json().then((data) => {
                              this.props.navigate(
                                `/${
                                  window.location.pathname.split("/")[1]
                                }/preview/${data.token}`
                              );
                            });
                          }
                        });
                      }}
                    >
                      <i class="fas fa-download"></i> Salvar{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default CreateCertTjSc;
