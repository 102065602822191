import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
class CreateCertTjRs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      docEmt: "CERTIDÃO",
      dados: {
        nomeSer: "",
        emss: "",
        nmrSer: "",
        emol1: 37.9,
        emol2: 6.6,
        vs1: 6.9,
        vs2: 6.9,
        tipo_certidao: "",
        nda: "Processamento eletrônico  de dados",
        cns: "",
        file: null,
      },
    };
  }

  componentDidMount() {
    document.title = "Emitir Certidão TJRS ";
    fetch(
      `${apiUrl}/listar-cartorios/` + window.location.pathname.split("/")[1],
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados,
        [name]: value,
      },
    }));
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    var { nomeSer, emol1, emol2, nmrSer, vs1, vs2, nda } = this.state.dados;
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Emitir Certidão TJRS{" "}
            </h2>
          </div>
        </header>
        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3>Emissão de certidão:</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label>Nome da Serventia:</label>

                      <select
                        class="form-control form-control-sm"
                        onChange={(event) => {
                          var slc =
                            this.state.data[event.target.selectedIndex - 1];

                          this.setState((prevState) => ({
                            dados: {
                              ...prevState.dados,
                              ["nomeSer"]: event.target.value,
                              ["nmrSer"]: slc.codeser,
                              ["cns"]: slc.Cns,
                            },
                          }));
                        }}
                      >
                        <option style={{ display: "none" }}></option>
                        {this.state.data.map((item, index) => {
                          return (
                            <>
                              <option value={item.nome}>{item.nome}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>

                    <div class="form-group mt-2">
                      <label>Número serventia:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={
                          nmrSer != ""
                            ? nmrSer
                            : "Selecione o nome da serventia."
                        }
                        disabled
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Nota:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value="Automático"
                        disabled
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Tipo de Certidão:</label>
                      <select
                        class="form-control"
                        onChange={(e) => {
                          this.setState((prevState) => ({
                            dados: {
                              ...prevState.dados,
                              ["tipo_certidao"]: e.target.value,
                            },
                          }));
                        }}
                      >
                        <option>Selecione:</option>
                        <option>Nasciento em inteiro teor</option>
                        <option>Casamento em inteiro teor</option>
                        <option>Óbito em inteiro teor</option>
                      </select>
                    </div>
                    <div class="form-group mt-2">
                      <strong>Data de </strong>
                      <label>Emissão:</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        name="emss"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Nome do ato:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value="Certidões expedidas, incluindo a busca"
                        disabled
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Cobrança:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value="SIM"
                        disabled
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Emolumento:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={emol1.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        name="emol1"
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Valor selo:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={vs1.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        name="vs1"
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Nome do Ato:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={nda}
                        disabled
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Cobrança:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value="SIM"
                        disabled
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Emolumento:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={emol2.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        name="emol2"
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Valor selo:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={vs2.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        name="vs2"
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>

                    <div class="mb-3">
                      <label for="exampleInput" class="form-label">
                        Arquivo:
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const allowedTypes = ["image/jpeg", "image/png"];
                            if (allowedTypes.includes(file.type)) {
                              this.setState({ file, errorMessage: "" });
                            } else {
                              e.target.value = "";
                              toast.warn(
                                "Only .jpg and .png files are allowed."
                              );
                            }
                          }
                        }}
                        id="formFile"
                      />
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-danger float-left ml-2"
                      onClick={() => {
                        this.props.navigate(-1);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faArrowLeft} /> Voltar
                    </button>

                    <button
                      type="submit"
                      class="btn btn-primary float-right ml-2"
                      onClick={(e) => {
                        var { dados, file } = this.state;

                        if (nomeSer == undefined) {
                          toast.error("Selecione o nome da Serventia!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }
                        if (nomeSer == "") {
                          toast.error("Selecione o nome da Serventia!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }

                        if (this.state.file == null) {
                          return toast.error("Selecione o arquivo!");
                        }

                        const formData = new FormData();

                        formData.append("file", file);
                        for (const key in dados) {
                          formData.append(key, dados[key]);
                        }
                        e.target.classList.add("load");

                        fetch(
                          `${apiUrl}/${
                            window.location.pathname.split("/")[1]
                          }/criar-certificado/`,
                          {
                            method: "POST",
                            credentials: "include",
                            headers: {
                              Authorization:
                                "Bearer " + Cookies.get("auth_token"),
                            },

                            body: formData,
                          }
                        )
                          .then((response) => {
                            e.target.classList.remove("load");
                            if (response.status == 200) {
                              response.json().then((data) => {
                                this.props.navigate(
                                  `/${
                                    window.location.pathname.split("/")[1]
                                  }/preview/${data.seg}`
                                );
                              });
                            }
                          })
                          .catch((err) => {
                            e.target.classList.add("load");
                          });
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faSave} /> Salvar{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default CreateCertTjRs;
