import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
class CreateFun extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      docEmt: "CERTIDÃO",
    };
  }

  componentDidMount() {
    document.title = "Emitir Certidão Funarpen ";
    fetch(
      `${apiUrl}/listar-cartorios/` + window.location.pathname.split("/")[1],
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Emitir Certidão Funarpen{" "}
            </h2>
          </div>
        </header>
        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3>Emissão de certidão:</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group mt-2">
                      <label>Nome:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          this.setState({ nome_cont: e.target.value });
                        }}
                      />
                    </div>
                    <div class="form-group">
                      <label>Nome do oficio:</label>

                      <select
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          this.setState({ nomeOfc: e.target.value });
                        }}
                      >
                        <option style={{ display: "none" }}></option>
                        {this.state.data.map((item, index) => {
                          return (
                            <>
                              <option value={item.id}>{item.nome}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div class="form-group mt-2">
                      <label>Documento emitido:</label>
                      <select
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          this.setState({ docEmt: e.target.value });
                        }}
                      >
                        {[
                          "CERTIDÃO",
                          "RECONHECIMENTO DE FIRMA POR VERDADEIRA",
                          "RECONHECIMENTO DE FIRMA POR SEMELHANÇA",
                          "CERTIDÃO DE NASCIMENTO",
                          "CERTIDÃO DE CASAMENTO",
                          "CERTIDÃO DE ÓBITO",
                          "CERTIDÃO DE NASCIMENTO – EM BREVE RELATO",
                          "CERTIDÃO DE CASAMENTO – EM BREVE RELATO",
                          "CERTIDÃO DE ÓBITO – EM INTEIRO BREVE RELATO",
                          "CERTIDÃO DE NASCIMENTO – EM INTEIRO TEOR",
                          "CERTIDÃO DE CASAMENTO - EM INTEIRO TEOR",
                          "CERTIDÃO DE ÓBITO - EM INTEIRO TEOR",
                          "CERTIDÃO DE NASCIMENTO – 2ª VIA",
                          "CERTIDÃO DE CASAMENTO – 2ª VIA",
                          "CERTIDÃO DE ÓBITO – 2ª VIA",
                          "CERTIDÃO DE NASCIMENTO – EM BREVE RELATO – 2ª VIA",
                          "CERTIDÃO DE CASAMENTO – EM BREVE RELATO – 2ª VIA",
                          "CERTIDÃO DE ÓBITO – EM INTEIRO BREVE RELATO – 2ª VIA",
                          "CERTIDÃO DE NASCIMENTO – EM INTEIRO TEOR – 2ª VIA",
                          "CERTIDÃO DE CASAMENTO - EM INTEIRO TEOR – 2ª VIA",
                          "CERTIDÃO DE ÓBITO - EM INTEIRO TEOR – 2ª VIA",
                          "PROCURAÇÃO PÚBLICA",
                          "ESCRITURA PÚBLICA",
                          "DECLARAÇÃO",
                          "PROCURAÇÃO",
                          "ESCRITURA PÚBLICA DECLARATÓRIA DE MATERNIDADE",
                          "ESCRITURA PÚBLICA DECLARATÓRIA DE PATERNIDADE",
                          "ESCRITURA PÚBLICA DE DIVÓRCIO CONSENSUAL",
                          "ESCRITURA PÚBLICA DE UNIÃO ESTÁVEL",
                        ].map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="form-group mt-2">
                      <label>Data de Geração:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          this.setState({ dataGer: e.target.value });
                        }}
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Número CPF/CNPJ:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.nmrFol}
                        onChange={(e) => {
                          this.setState({ nmrFol: e.target.value });
                        }}
                      />
                    </div>
                    <div class="form-group mt-2">
                      <label>Valor Total:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          this.setState({ nmrAss: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-danger float-left ml-2"
                      onClick={() => {
                        this.props.navigate(-1);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Voltar
                    </button>

                    <button
                      type="submit"
                      class="btn btn-primary float-right ml-2"
                      onClick={() => {
                        if (this.state.nomeOfc == undefined) {
                          toast.error("Selecione um nome de oficio!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }
                        if (this.state.nomeOfc == "") {
                          toast.error("Selecione um nome de oficio!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }
                        const id = toast.loading("Enviando...");
                        fetch(
                          `${apiUrl}/${
                            window.location.pathname.split("/")[1]
                          }/criar-certificado/`,
                          {
                            method: "POST",
                            credentials: "include",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization:
                                "Bearer " + Cookies.get("auth_token"),
                            },
                            body: JSON.stringify({
                              nome_cont: this.state.nome_cont,
                              nomeOfc: this.state.nomeOfc,
                              docEmt: this.state.docEmt,
                              dataGer: this.state.dataGer,
                              nmrLiv: this.state.nmrLiv,
                              nmrFol: this.state.nmrFol || "*****",
                              nmrAss: this.state.nmrAss,
                              nomeCont: this.state.nomeCont,
                              nomeCont2: this.state.nomeCont2,
                            }),
                          }
                        ).then((response) => {
                          if (response.status == 200) {
                            toast.update(id, {
                              render: "Certificadi criado com sucesso!",
                              type: "success",
                              isLoading: false,
                              autoClose: 3000,
                            });

                            response.json().then((data) => {
                              this.props.navigate(
                                `/${
                                  window.location.pathname.split("/")[1]
                                }/preview/${data.token}`
                              );
                            });
                          }
                        });
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon color="#fff" icon={faSave} /> Salvar{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default CreateFun;
