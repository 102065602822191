import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
class CreateCertTjMg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      docEmt: "CERTIDÃO",
      referencia: "2024",
      codigo_fiscal_ato: "7803",
      codigo_seguranca: "",
      serventia: "",
      data_pratica_ato: "",
      data_hora_utilizacao_selo: "",
      data_hora_transmissao_ato: "",
      tipo_tributacao: "",
      valor_emolumento: 96.84,
      valor_taxa_fiscalizacao_judiciaria: 19.55,
      valor_final_usuario: 116.39,
      protocolo: "",
      tipo_livro: "",
      ordem_sequencial: "",
      folha: "",
      tipo_de_livro: undefined,
      tipo_escrituracao: "",
      numero_ordem: "",
      especie_certidao: "",
      detalhamento_certidao: "8 CERTIDOES: 81 CERTIDAO DE LIVROS: 812 etc ",
      nome_pessoa: "",
      cpf: "",
      complemento_certidao: "Certidão de atos praticados na própria Serventia",
      atos: [],
      reg_bens: false,
      text_rbens: "",
      opcs: [
        { value: "Selecione:", label: "Selecione:" },
        {
          value:
            "9 - Havendo no termo uma ou mais averbações ou anotações, acrescer ao valor da certidão",
          label:
            "9 - Havendo no termo uma ou mais averbações ou anotações, acrescer ao valor da certidão",
          taxa: [9.43, 1.21, 10.64],
        },

        {
          value: "1 - Arquivamento (por folha)",
          label: "1 - Arquivamento (por folha)",
          taxa: [9.32, 2.92, 12.24],
        },
      ],
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  formatToBRL(amount) {
    // Convertendo o número para o formato BRL
    const formattedAmount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(amount);

    return formattedAmount;
  }

  componentDidMount() {
    document.title = "Emitir Certidão TJMG ";
    fetch(
      `${apiUrl}/listar-cartorios/` + window.location.pathname.split("/")[1],
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Emitir Certidão TJMG{" "}
            </h2>
          </div>
        </header>
        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3>Emissão de certidão:</h3>
                  </div>
                  <div class="card-body">
                    <div
                      class="form-group mt-3 mb-3"
                      style={{ display: "none" }}
                    >
                      <label>Tipo do ato:</label>
                      <select
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          this.setState({ docEmt: e.target.value });
                        }}
                      >
                        {[
                          { value: "CERTIDÃO", label: "CERTIDÃO" },
                          {
                            value: "CERTIDÃO DE NASCIMENTO",
                            label: "CERTIDÃO DE NASCIMENTO",
                          },
                          {
                            value: "CERTIDÃO DE CASAMENTO",
                            label: "CERTIDÃO DE CASAMENTO",
                          },
                          {
                            value: "CERTIDÃO DE ÓBITO",
                            label: "CERTIDÃO DE ÓBITO",
                          },
                          {
                            value: "PROCURAÇÃO PÚBLICA",
                            label: "PROCURAÇÃO PÚBLICA",
                          },
                          {
                            value: "ESCRITURA PÚBLICA",
                            label: "ESCRITURA PÚBLICA",
                          },
                          {
                            value: "NASCIMENTO INTEIRO TEOR",
                            label: "NASCIMENTO INTEIRO TEOR",
                          },
                          {
                            value: "CASAMENTO BREVE RELATO",
                            label: "CASAMENTO BREVE RELATO",
                          },
                          {
                            value: "CASAMENTO INTEIRO TEOR",
                            label: "CASAMENTO INTEIRO TEOR",
                          },
                          {
                            value: "ÓBITO BREVE RELATO",
                            label: "ÓBITO BREVE RELATO",
                          },
                          {
                            value: "ÓBITO INTEIRO TEOR",
                            label: "ÓBITO INTEIRO TEOR",
                          },
                          { value: "DECLARAÇÃO", label: "DECLARAÇÃO" },
                          { value: "PROCURAÇÃO", label: "PROCURAÇÃO" },
                        ].map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group mt-2">
                      <label>Referência:</label>
                      <input
                        type="text"
                        name="referencia"
                        value={this.state.referencia}
                        className="form-control form-control-sm"
                        disabled="true"
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label>Código Fiscal do Ato:</label>
                      <input
                        type="text"
                        name="codigo_fiscal_ato"
                        value={this.state.codigo_fiscal_ato}
                        className="form-control form-control-sm"
                        disabled="true"
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label>Código de Segurança:</label>
                      <input
                        type="text"
                        name="codigo_seguranca"
                        value="Automático"
                        className="form-control form-control-sm"
                        disabled="true"
                      />
                    </div>
                    <div class="form-group">
                      <label>Nome da Serventia:</label>
                      <select
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          const idSelecionado = parseInt(e.target.value);
                          const itemSelecionado = this.state.data.find(
                            (item) => item.id === idSelecionado
                          );

                          this.setState({
                            nomeSer: e.target.value,
                            resp: itemSelecionado.resp,
                            quali: itemSelecionado.Cma,
                          });
                        }}
                      >
                        <option>Selecione:</option>
                        {this.state.data.map((item, index) => {
                          return (
                            <>
                              <option value={item.id}>{item.nome}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group mt-2">
                      <label>Data da Prática do Ato:</label>
                      <input
                        type="date"
                        name="data_pratica_ato"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label>Data e Hora de Utilização do Selo:</label>
                      <input
                        type="datetime-local"
                        name="data_hora_utilizacao_selo"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label>Data e Hora de Transmissão do Ato:</label>
                      <input
                        type="datetime-local"
                        name="data_hora_transmissao_ato"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label>Tipo de Tributação:</label>
                      <input
                        type="text"
                        name="tipo_tributacao"
                        value="NORMAL"
                        disabled="true"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label>Valor do Emolumento:</label>
                      <input
                        type="text"
                        disabled="true"
                        value={this.state.valor_emolumento.toLocaleString(
                          "pt-BR",
                          { style: "currency", currency: "BRL" }
                        )}
                        name="valor_emolumento"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label>Valor da Taxa de Fiscalização Judiciária:</label>
                      <input
                        type="text"
                        disabled="true"
                        value={this.state.valor_taxa_fiscalizacao_judiciaria.toLocaleString(
                          "pt-BR",
                          { style: "currency", currency: "BRL" }
                        )}
                        name="valor_taxa_fiscalizacao_judiciaria"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label>Valor Final do Usuário:</label>
                      <input
                        type="text"
                        disabled="true"
                        value={this.state.valor_final_usuario.toLocaleString(
                          "pt-BR",
                          { style: "currency", currency: "BRL" }
                        )}
                        name="valor_final_usuario"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label>Protocolo:</label>
                      <input
                        type="text"
                        name="protocolo"
                        disabled="true"
                        value="Automático"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div
                      className="form-group mt-2"
                      style={{ display: "none" }}
                    >
                      <label>Ordem Sequencial:</label>
                      <input
                        type="text"
                        name="ordem_sequencial"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div
                      className="form-group mt-2"
                      style={{ display: "none" }}
                    >
                      <label>Folha:</label>
                      <input
                        type="text"
                        name="folha"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div
                      className="form-group mt-2"
                      style={{ display: "none" }}
                    >
                      <label>Tipo de Escrituração:</label>
                      <input
                        type="text"
                        name="tipo_escrituracao"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div
                      className="form-group mt-2"
                      style={{ display: "none" }}
                    >
                      <label>Número da Ordem:</label>
                      <input
                        type="text"
                        name="numero_ordem"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label>Detalhamento da Certidão:</label>
                      <textarea
                        name="detalhamento_certidao"
                        disabled="true"
                        value={this.state.detalhamento_certidao}
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      ></textarea>
                    </div>
                    <div className="form-group mt-2">
                      <label>Nome da Pessoa:</label>
                      <input
                        type="text"
                        name="nome_pessoa"
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label>Cpf:</label>
                      <input
                        type="text"
                        name="cpf"
                        maxLength={11}
                        value={this.state.cpf}
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          const { value } = e.target;
                          if (/^\d*$/.test(value)) {
                            this.setState({ cpf: value });
                          }
                        }}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label>Complemento da Certidão:</label>
                      <input
                        name="complemento_certidao"
                        disabled="true"
                        value={this.state.complemento_certidao}
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      ></input>
                    </div>

                    <div className="form-group mt-2">
                      <label>Nome do responsavél pela prática do ato:</label>
                      <input
                        disabled="true"
                        value={this.state.resp}
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label>
                        Qualificaçãos do responsavél pela prática do ato:
                      </label>
                      <input
                        name="resp_ptca"
                        disabled="true"
                        value={this.state.quali}
                        className="form-control form-control-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group mt-2 mb-2">
                      <label>Tipo de livro:</label>
                      <select
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          var type = e.target.selectedIndex;
                          if (type == 2 || type == 3) {
                            this.setState({ reg_bens: true });
                          } else {
                            this.setState({ reg_bens: false });
                          }
                          this.setState({ tipo_de_livro: e.target.value });
                        }}
                      >
                        <option>Selecone:</option>
                        <option value={701}>701 - A - Nascimento</option>
                        <option value={702}>702 - B - Casamento</option>
                        <option value={703}>
                          703 - B-Aux Casamento religioso
                        </option>
                        <option value={704}>704 - C - Óbito</option>
                        <option value={705}>705 - C-Aux - Natimorto</option>
                        <option value={706}>706 - D - Proclamas</option>
                        <option value={707}>
                          707 - E - Inscrição Registro Civil
                        </option>
                      </select>
                    </div>
                    {this.state.reg_bens == true && (
                      <>
                        <div className="form-group mt-2">
                          <label>Regime de bens</label>

                          <select
                            className="form-control form-control-sm"
                            onChange={(e) => {
                              this.setState({ text_rbens: e.target.value });
                            }}
                          >
                            <option>SELECIONE:</option>
                            {[
                              "COMUNHÃO PARCIAL DE BENS",
                              "COMUNHÃO UNIVERSAL DE BENS",
                              "SEPARAÇÃO TOTAL DE BENS",
                              "PARTICIPAÇÃO FINAL NOS AQUESTOS",
                              "SEPARAÇÃO OBRIGATÓRIA DE BENS",
                              "REGIME MISTO",
                            ].map((item, index) => (
                              <>
                                <option value={item}>{item}</option>
                              </>
                            ))}
                          </select>
                        </div>
                      </>
                    )}

                    <hr />

                    {this.state.atos.length > 0 && (
                      <>
                        {this.state.atos.map((form, index) => (
                          <>
                            <div
                              class="card"
                              key={index}
                              style={{
                                marginBottom: 15,
                              }}
                            >
                              <div class="card-header">
                                <h3>Ato: {index + 2}</h3>
                              </div>
                              <div class="card-body">
                                <div class="form-group mt-2">
                                  <label>Tipo do ato:</label>
                                  <select
                                    class="form-control form-control-sm"
                                    name="ato"
                                    onChange={(e) => {
                                      const selectedIndex =
                                        e.target.selectedIndex;

                                      const item =
                                        this.state.opcs[selectedIndex].taxa;
                                      if (item == undefined) {
                                        return;
                                      }

                                      this.setState((prevState) => {
                                        const atos = [...prevState.atos];
                                        atos[index]["ato"] = e.target.value;
                                        atos[index]["v1"] = this.formatToBRL(
                                          item[0]
                                        );
                                        atos[index]["v2"] = this.formatToBRL(
                                          item[1]
                                        );
                                        atos[index]["v3"] = this.formatToBRL(
                                          item[0] + item[1]
                                        );
                                        atos[index]["type"] = selectedIndex;
                                        if (selectedIndex == 2) {
                                          delete atos[index]["folha"];
                                          delete atos[index]["tipo_de_livro"];
                                          delete atos[index]["orderm_s"];
                                          delete atos[index]["folha"];
                                          delete atos[index]["tipo_dec"];
                                          delete atos[index]["nmr_dor"];
                                        }

                                        return { atos };
                                      });
                                    }}
                                  >
                                    {this.state.opcs.map((option, index) => (
                                      <option key={index} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {form.ato != undefined && (
                                  <>
                                    {form.ato ==
                                    "9 - Havendo no termo uma ou mais averbações ou anotações, acrescer ao valor da certidão" ? (
                                      <>
                                        <div className="form-group mt-2">
                                          <label>Valor do Emolumento:</label>
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={form.v1}
                                            disabled="true"
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>
                                            Valor da Taxa de Fiscalização
                                            Judiciária:
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={form.v2}
                                            disabled="true"
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>Valor Final ao Usuário:</label>
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={form.v3}
                                            disabled="true"
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>Código fiscal do ato:</label>
                                          <input
                                            type="text"
                                            disabled="true"
                                            className="form-control form-control-sm"
                                            value="Automático"
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>Protocolo:</label>
                                          <input
                                            type="text"
                                            disabled="true"
                                            className="form-control form-control-sm"
                                            value="Automático"
                                          />
                                        </div>

                                        <div className="form-group mt-2 mb-2">
                                          <label>Tipo de livro:</label>
                                          <select
                                            class="form-control form-control-sm"
                                            onChange={(e) => {
                                              this.setState((prevState) => {
                                                const atos = [
                                                  ...prevState.atos,
                                                ];
                                                atos[index]["tipo_de_livro"] =
                                                  e.target.value;
                                                return { atos };
                                              });
                                            }}
                                          >
                                            <option>Selecone:</option>
                                            <option value={701}>
                                              A - Nascimento
                                            </option>
                                            <option value={702}>
                                              B - Casamento
                                            </option>
                                            <option value={703}>
                                              B-Aux Casamento religioso
                                            </option>
                                            <option value={704}>
                                              C - Óbito
                                            </option>
                                            <option value={705}>
                                              C-Aux - Natimorto
                                            </option>
                                            <option value={706}>
                                              D - Proclamas
                                            </option>
                                            <option value={707}>
                                              E - Inscrição Registro Civil
                                            </option>
                                          </select>
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>
                                            Ordem sequencial do livro (L),
                                            matrícula (M) ou ficha (F) :
                                          </label>
                                          <input
                                            type="text"
                                            onChange={(e) => {
                                              this.setState((prevState) => {
                                                const atos = [
                                                  ...prevState.atos,
                                                ];
                                                atos[index]["orderm_s"] =
                                                  e.target.value;
                                                return { atos };
                                              });
                                            }}
                                            className="form-control form-control-sm"
                                            value={form.orderm_s}
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>Folha:</label>
                                          <input
                                            type="text"
                                            onChange={(e) => {
                                              this.setState((prevState) => {
                                                const atos = [
                                                  ...prevState.atos,
                                                ];
                                                atos[index]["folha"] =
                                                  e.target.value;
                                                return { atos };
                                              });
                                            }}
                                            className="form-control form-control-sm"
                                            value={form.folha}
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>Tipo de Escrituração:</label>
                                          <input
                                            type="text"
                                            disabled="true"
                                            className="form-control form-control-sm"
                                            value="REGISTRO"
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>Número de Ordem:</label>
                                          <input
                                            type="text"
                                            onChange={(e) => {
                                              this.setState((prevState) => {
                                                const atos = [
                                                  ...prevState.atos,
                                                ];
                                                atos[index]["nmr_dor"] =
                                                  e.target.value;
                                                return { atos };
                                              });
                                            }}
                                            className="form-control form-control-sm"
                                            value={form.nmr_dor}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="form-group mt-2">
                                          <label>Valor do Emolumento:</label>
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={form.v1}
                                            disabled="true"
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>
                                            Valor da Taxa de Fiscalização
                                            Judiciária:
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={form.v2}
                                            disabled="true"
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>Valor Final ao Usuário:</label>
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={form.v3}
                                            disabled="true"
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>Código fiscal do ato:</label>
                                          <input
                                            type="text"
                                            disabled="true"
                                            className="form-control form-control-sm"
                                            value="Automático"
                                          />
                                        </div>

                                        <div className="form-group mt-2">
                                          <label>Protocolo:</label>
                                          <input
                                            type="text"
                                            disabled="true"
                                            className="form-control form-control-sm"
                                            value="Automático"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                    <br />

                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState((prevState) => ({
                          atos: [...prevState.atos, {}],
                        }));
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Adicionar ato
                    </button>
                  </div>

                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-danger float-left ml-2"
                      onClick={() => {
                        this.props.navigate(-1);
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} /> Voltar
                    </button>

                    <button
                      type="submit"
                      class="btn btn-primary float-right ml-2"
                      onClick={(e) => {
                        e.target.classList.add("load");

                        console.log(this.state.atos);

                        if (this.state.nomeSer == undefined) {
                          toast.error("Selecione o nome da Serventia!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }
                        if (this.state.nomeSer == "") {
                          toast.error("Selecione o nome da Serventia!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }

                        // const id = toast.loading("Enviando...");
                        var body = this.state;

                        fetch(
                          `${apiUrl}/${
                            window.location.pathname.split("/")[1]
                          }/criar-certificado/`,
                          {
                            method: "POST",
                            credentials: "include",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization:
                                "Bearer " + Cookies.get("auth_token"),
                            },
                            body: JSON.stringify({
                              serventia: body.nomeSer,
                              referencia: body.referencia,
                              codigo_fiscal_ato: body.codigo_fiscal_ato,
                              codigo_seguranca: body.codigo_seguranca,
                              data_pratica_ato: body.data_pratica_ato,
                              data_hora_utilizacao_selo:
                                body.data_hora_utilizacao_selo,
                              data_hora_transmissao_ato:
                                body.data_hora_transmissao_ato,
                              tipo_tributacao: body.tipo_tributacao,
                              valor_emolumento: body.valor_emolumento,
                              valor_taxa_fiscalizacao_judiciaria:
                                body.valor_taxa_fiscalizacao_judiciaria,
                              valor_final_usuario: body.valor_final_usuario,
                              resp: body.resp,
                              quali: body.quali,
                              cpf: body.cpf,
                              protocolo: body.protocolo,
                              tipo_de_livro: body.tipo_de_livro,
                              tipo_de_ato: body.docEmt,
                              tipo_de_livro: body.tipo_de_livro,
                              ordem_sequencial: body.ordem_sequencial,
                              folha: body.folha,
                              tipo_escrituracao: body.tipo_escrituracao,
                              numero_ordem: body.numero_ordem,
                              especie_certidao: body.especie_certidao,
                              detalhamento_certidao: body.detalhamento_certidao,
                              nome_pessoa: body.nome_pessoa,
                              complemento_certidao: body.complemento_certidao,
                              reg_bens: body.text_rbens,
                              atos: this.state.atos,
                            }),
                          }
                        )
                          .then((response) => {
                            e.target.classList.remove("load");
                            if (response.status == 200) {
                              //toast.update(id, { render:"Certificado criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});

                              response.json().then((data) => {
                                this.props.navigate(
                                  `/${
                                    window.location.pathname.split("/")[1]
                                  }/preview/${data.token}`
                                );
                              });
                            }
                          })
                          .catch((err) => {});
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} /> Salvar{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default CreateCertTjMg;
