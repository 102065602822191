import React, { Component } from "react";
import logo from "../../../src/images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faThermometer } from "@fortawesome/free-solid-svg-icons";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableMenu: false,
      theme: localStorage.getItem("theme") || "light", // Recupera o tema do localStorage
    };
  }

  componentDidMount() {
    // Define o tema ao carregar o componente
    document.body.setAttribute("theme", this.state.theme);
    document.documentElement.setAttribute("theme", this.state.theme);
  }

  toggleTheme = () => {
    const newTheme = this.state.theme === "light" ? "dark" : "light";
    this.setState({ theme: newTheme }, () => {
      // Atualiza o atributo do body e salva no localStorage
      document.body.setAttribute("theme", newTheme);
      localStorage.setItem("theme", newTheme);
      document.documentElement.setAttribute("theme", newTheme);
    });
  };

  getClassNames = ({ isActive }) => {
    return isActive
      ? "inline-flex items-center px-1 pt-1 border-b-2 border-indigo-400 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-indigo-700 "
      : "inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300";
  };

  render() {
    return (
      <>
        <nav className="">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16 items-center">
              {/* Left section */}
              <div className="flex">
                <div className="shrink-0 flex items-center">
                  <Link to="/">
                    <img src={logo} alt="Logo" />
                  </Link>
                </div>

                {/* Navigation Links */}
                <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                  <NavLink exact className={this.getClassNames} to="/">
                    Inicio
                  </NavLink>
                  <NavLink className={this.getClassNames} to="/funarpen">
                    TJPR
                  </NavLink>
                  <NavLink className={this.getClassNames} to="/tjsp">
                    TJSP
                  </NavLink>
                  <NavLink className={this.getClassNames} to="/tjrj">
                    TJRJ
                  </NavLink>
                  <NavLink className={this.getClassNames} to="/tjmg">
                    TJMG
                  </NavLink>
                  <NavLink className={this.getClassNames} to="/tjgo">
                    TJGO
                  </NavLink>
                  <NavLink className={this.getClassNames} to="/tjrs">
                    TJRS
                  </NavLink>
                  <NavLink className={this.getClassNames} to="/tjsc">
                    TJSC
                  </NavLink>
                  <NavLink className={this.getClassNames} to="/tjes">
                    TJES
                  </NavLink>
                  <a
                    href="https://arvore.webcidadania.com/"
                    className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                  >
                    Genealogia
                  </a>
                  <NavLink className={this.getClassNames} to="/apostil">
                    Apostil
                  </NavLink>
                </div>
              </div>

              {/* Right section - Buttons */}
              <div className="btn-func flex items-center space-x-4">
                <button
                  className="px-4 py-2 bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  onClick={this.toggleTheme}
                >
                  Tema: {this.state.theme === "light" ? "Claro" : "Escuro"}
                </button>
                <button
                  onClick={() => {
                    const cookies = document.cookie.split(";");

                    cookies.forEach((cookie) => {
                      const name = cookie.split("=")[0].trim();
                      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                    });
                    window.location = "/";
                  }}
                  className="px-4 py-2 bg-green-500 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
                >
                  <FontAwesomeIcon icon={faSignOut} />
                </button>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;
