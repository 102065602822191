import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
class CreateCertTjGo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      tjgo: {
        civilato: "",
        docEmt:
          "3001 - 83 V - Segundas vias de certidão de nascimento, casamento, óbito e certidões negativas",
        respo: "",
        emolumentos: "R$ 51,65",
        taxa: "R$ 18,29",
        taxa_j: "R$ 18,29",
        fundos_e: "R$ 10,98",
        edrc: "",
        cidade: "",
        telefone: "",
        data: "",
        code: "",
      },
    };
  }

  componentDidMount() {
    document.title = "Emitir Certidão TJGo ";
    fetch(
      `${apiUrl}/listar-cartorios/` + window.location.pathname.split("/")[1],
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Emitir Certidão TJGO{" "}
            </h2>
          </div>
        </header>
        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3>Emissão de certidão:</h3>
                  </div>
                  <div class="card-body">
                    <label>Civil do Ato :</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          tjgo: {
                            ...prevState.tjgo,
                            civilato: e.target.value,
                          },
                        }));
                      }}
                    />

                    <div class="form-group">
                      <label>Nome da Serventia:</label>

                      <select
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          const selectedIndex = e.target.selectedIndex;
                          const item = this.state.data[selectedIndex - 1];

                          this.setState((prevState) => ({
                            tjgo: {
                              ...prevState.tjgo,
                              code: item.codeser,
                              nomeSer: e.target.value,
                              edrc: item.edrc,
                              cidade: item.cidade,
                              telefone: item.telefone,
                            },
                          }));

                          // this.setState({nomeSer:e.target.value})
                        }}
                      >
                        <option style={{ display: "none" }}></option>
                        {this.state.data.map((item, index) => {
                          return (
                            <>
                              <option value={item.id}>{item.nome}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div class="form-group mt-2">
                      <label>Tipo do ato:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.tjgo.docEmt}
                        disabled="true"
                      />
                    </div>
                    <div class="form-group mt-2">
                      <label>Nome do serventuário que praticou o ato:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          this.setState((prevState) => ({
                            tjgo: {
                              ...prevState.tjgo,
                              respo: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Emolumento:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.tjgo.emolumentos}
                        disabled="true"
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Taxa Judiciário:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.tjgo.taxa_j}
                        disabled="true"
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Fundos Estaduais:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.tjgo.fundos_e}
                        disabled="true"
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Endereço:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.tjgo.edrc}
                        disabled="true"
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Cidade/UF:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.tjgo.cidade}
                        disabled="true"
                      />
                    </div>

                    <div class="form-group mt-2">
                      <label>Telefone:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.tjgo.telefone}
                        disabled="true"
                      />
                    </div>
                    <div class="form-group mt-2">
                      <label>Data:</label>
                      <InputMask
                        mask="99/99/9999 99:99"
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.tjgo.data}
                        onChange={(e) => {
                          this.setState((prevState) => ({
                            tjgo: {
                              ...prevState.tjgo,
                              data: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-danger float-left ml-2"
                      onClick={() => {
                        this.props.navigate(-1);
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} /> Voltar
                    </button>

                    <button
                      type="submit"
                      class="btn btn-primary float-right ml-2"
                      onClick={() => {
                        if (this.state.tjgo.nomeSer == undefined) {
                          toast.error("Selecione o nome da Serventia!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }
                        if (this.state.tjgo.nomeSer == "") {
                          toast.error("Selecione o nome da Serventia!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }

                        if (this.state.tjgo.data.trim().length == 0) {
                          toast.error("Informe a data!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }
                        const id = toast.loading("Enviando...");
                        fetch(
                          `${apiUrl}/${
                            window.location.pathname.split("/")[1]
                          }/criar-certificado/`,
                          {
                            method: "POST",
                            credentials: "include",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization:
                                "Bearer " + Cookies.get("auth_token"),
                            },
                            body: JSON.stringify(this.state.tjgo),
                          }
                        )
                          .then((response) => {
                            if (response.status == 200) {
                              toast.update(id, {
                                render: "Certificado criado com sucesso!",
                                type: "success",
                                isLoading: false,
                                autoClose: 3000,
                              });

                              response.json().then((data) => {
                                this.props.navigate(
                                  `/${
                                    window.location.pathname.split("/")[1]
                                  }/preview/${data.token}`
                                );
                              });
                            }
                          })
                          .catch((err) => {
                            toast.update(id, {
                              render: "erro!",
                              type: "error",
                              isLoading: false,
                              autoClose: 3000,
                            });
                          });
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} /> Salvar{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default CreateCertTjGo;
