import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
class CreateCertTjSp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      docEmt: "",
      busca: [],
      nmrDoc: "aaaa",
    };
    this.inputRef = React.createRef();
    this.selcet1 = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    document.title = "Emitir Certidão TJSP ";
    document.addEventListener("mousedown", this.handleClickOutside);
    fetch(
      `${apiUrl}/listar-cartorios/` + window.location.pathname.split("/")[1],
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.inputRef.current &&
      !this.inputRef.current.contains(event.target)
    ) {
      this.setState({ bsc: false });
    }
  }

  handleFocus() {
    this.setState({ message: "" });
  }
  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Emitir Certidão TJSP{" "}
            </h2>
          </div>
        </header>
        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3>Emissão de certidão:</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label>Nome da Serventia:</label>

                      <select
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          const selectedId = parseInt(e.target.value, 10);
                          const selectedItem = this.state.data.find(
                            (item) => item.id === selectedId
                          );

                          toast.info("Cns preenchido automático!");
                          this.setState((prevState) => ({
                            ...prevState,
                            nomeSer: e.target.value,
                            cns: selectedItem.Cns,
                          }));
                        }}
                      >
                        <option style={{ display: "none" }}></option>
                        {this.state.data.map((item, index) => {
                          return (
                            <>
                              <option value={item.id} key={item.id}>
                                {item.nome}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div class="form-group mt-2">
                      <label>Tipo do ato:</label>
                      <select
                        class="form-control form-control-sm"
                        onChange={(e) => {
                          this.setState({ docEmt: e.target.value });
                        }}
                        value={this.state.docEmt}
                      >
                        {[
                          "ESCRITURA PÚBLICA DE COMPRA E VENDA DE IMÓVEL",
                          "CERTIDÃO",
                          "CERTIDÃO DE NASCIMENTO",
                          "CERTIDÃO DE CASAMENTO",
                          "CERTIDÃO DE ÓBITO",
                          "CERTIDÃO DE NASCIMENTO – EM BREVE RELATO",
                          "CERTIDÃO DE CASAMENTO – EM BREVE RELATO",
                          "CERTIDÃO DE ÓBITO – EM INTEIRO BREVE RELATO",
                          "CERTIDÃO DE NASCIMENTO – EM INTEIRO TEOR",
                          "CERTIDÃO DE CASAMENTO - EM INTEIRO TEOR",
                          "CERTIDÃO DE ÓBITO - EM INTEIRO TEOR",
                          "PROCURAÇÃO PÚBLICA",
                          "ESCRITURA PÚBLICA",
                          "DECLARAÇÃO",
                          "PROCURAÇÃO",
                          "ESCRITURA PÚBLICA DECLARATÓRIA DE MATERNIDADE",
                          "ESCRITURA PÚBLICA DECLARATÓRIA DE PATERNIDADE",
                          "ESCRITURA PÚBLICA DE DIVÓRCIO CONSENSUAL",
                          "ESCRITURA PÚBLICA DE UNIÃO ESTÁVEL",
                        ].map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="form-group mt-2">
                      <label>CNS:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.cns}
                        onChange={(e) => {
                          this.setState({ cns: e.target.value.toUpperCase() });
                        }}
                      />
                    </div>
                    <div class="form-group mt-2" ref={this.inputRef}>
                      <label>Iniciais do Requerente:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.iniReq}
                        onClick={() => {
                          if (this.state.busca.length > 0) {
                            this.setState({ bsc: true });
                          }
                        }}
                        onChange={(e) => {
                          this.setState({ iniReq: e.target.value, bsc: false });
                          if (e.target.value == 0) {
                            this.setState({ bsc: false });
                            return false;
                          }
                          fetch(`${apiUrl}/tjsp/busca-requerente/`, {
                            method: "POST",
                            credentials: "include",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization:
                                "Bearer " + Cookies.get("auth_token"),
                            },
                            body: JSON.stringify({
                              nome: e.target.value,
                            }),
                          }).then((response) => {
                            response.json().then((data) => {
                              this.setState({ bsc: true, busca: data });
                            });
                          });
                        }}
                      />
                      {this.state.bsc == true && (
                        <>
                          <div className="area-busca">
                            {this.state.busca.length > 0 ? (
                              <>
                                {this.state.busca.map((item) => (
                                  <>
                                    <div
                                      className="item"
                                      onClick={() => {
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          nomeSer: item.nomeSer,
                                          docEmt: item.docEmt,
                                        }));

                                        this.setState({
                                          nomeSer: item.nomeSer,
                                          docEmt: item.docEmt,
                                          iniReq: item.iniReq,
                                          cns: item.cns,
                                          nmrDoc: item.nmrDoc,
                                          dataGer: item.dataGer,
                                          nmrDoc: item.nmrDoc,
                                          valor: item.valor,
                                          atovi: item.atovi,
                                          bsc: false,
                                        });
                                      }}
                                    >
                                      {item.iniReq}
                                    </div>
                                  </>
                                ))}
                              </>
                            ) : (
                              <>Sem resultados!</>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div class="form-group mt-2">
                      <label>Número CPF/CNPJ:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.nmrDoc}
                        onChange={(e) => {
                          this.setState({ nmrDoc: e.target.value });
                        }}
                      />
                    </div>
                    <div class="form-group mt-2">
                      <label>Data e Hora do Ato Praticado:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.dataGer}
                        onChange={(e) => {
                          this.setState({ dataGer: e.target.value });
                        }}
                      />
                    </div>
                    <div class="form-group mt-2">
                      <label>Valor do Ato:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.valor}
                        onChange={(e) => {
                          this.setState({ valor: e.target.value });
                        }}
                      />
                    </div>
                    <div class="form-group mt-2">
                      <label>Ato vinculador</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        value={this.state.atovi}
                        onChange={(e) => {
                          this.setState({ atovi: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-danger float-left ml-2"
                      onClick={() => {
                        this.props.navigate(-1);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faArrowLeft} /> Voltar
                    </button>

                    <button
                      type="submit"
                      class="btn btn-primary float-right ml-2"
                      onClick={() => {
                        if (this.state.nomeSer == undefined) {
                          toast.error("Selecione o nome da Serventia!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }
                        if (this.state.nomeSer == "") {
                          toast.error("Selecione o nome da Serventia!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return false;
                        }
                        const id = toast.loading("Enviando...");
                        fetch(
                          `${apiUrl}/${
                            window.location.pathname.split("/")[1]
                          }/criar-certificado/`,
                          {
                            method: "POST",
                            credentials: "include",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization:
                                "Bearer " + Cookies.get("auth_token"),
                            },
                            body: JSON.stringify({
                              nomeSer: this.state.nomeSer,
                              docEmt: this.state.docEmt,
                              cns: this.state.cns,
                              iniReq: this.state.iniReq,
                              nmrDoc: this.state.nmrDoc,
                              dataGer: this.state.dataGer,
                              valor: this.state.valor,
                              atovi: this.state.atovi,
                            }),
                          }
                        ).then((response) => {
                          if (response.status == 200) {
                            toast.update(id, {
                              render: "Certificado criado com sucesso!",
                              type: "success",
                              isLoading: false,
                              autoClose: 3000,
                            });

                            response.json().then((data) => {
                              this.props.navigate(
                                `/${
                                  window.location.pathname.split("/")[1]
                                }/preview/${data.token}`
                              );
                            });
                          }
                        });
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faSave} /> Salvar{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default CreateCertTjSp;
