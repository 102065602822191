import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
class EditSlc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: [],
      Loader: true,
      data: [],
    };
  }

  componentDidMount() {
    document.title = " Editar Solicitante ";
    fetch(
      `${apiUrl}/tjsc/solicitante/${window.location.pathname.split("/")[4]}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          dados: data,
          Loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    var dados = this.state.dados;
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Editar Solicitante{" "}
            </h2>
          </div>
        </header>

        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3>Dados:</h3>
                  </div>
                  <div className="card-body">
                    <div className="">
                      <div className="row">
                        <div className="col mb-3">
                          <label>Nome:</label>
                          <input
                            type="text"
                            name="nome"
                            value={dados.nome}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mb-3">
                          <label>Data de Nascimento:</label>
                          <input
                            type="date"
                            name="dataNascimento"
                            value={this.state.dados.dataNascimento}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mb-3">
                          <label>Profissão:</label>
                          <input
                            type="text"
                            name="profissao"
                            value={this.state.dados.profissao}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col mb-3">
                          <label>Pessoas:</label>
                          <select
                            name="pessoa"
                            className="form-control form-control-sm"
                            disabled
                          >
                            <option>Física</option>
                            <option>Jurídica</option>
                          </select>
                        </div>
                        <div className="col mb-3">
                          <label>Estado Civil:</label>
                          <select
                            name="estadoCivil"
                            value={dados.estadoCivil}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          >
                            <option>Selecione</option>
                            <option value="solteiro">Solteiro(a)</option>
                            <option value="casado">Casado(a)</option>
                            <option value="divorciado">Divorciado(a)</option>
                            <option value="viuvo">Viúvo(a)</option>
                            <option value="uniao-estavel">União Estável</option>
                            <option value="separado">Separado(a)</option>
                          </select>
                        </div>
                        <div className="col mb-3">
                          <label>Nacionalidade:</label>
                          <input
                            type="text"
                            name="nacionalidade"
                            disabled
                            value="Brasil"
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mb-3">
                          <label>Sexo:</label>
                          <select
                            name="sexo"
                            value={dados.sexo}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          >
                            <option>Selecione</option>
                            <option value="feminino">Feminino</option>
                            <option value="masculino">Masculino</option>
                            <option value="outro">Outro</option>
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col mb-3">
                          <label>Tipo de Documento:</label>
                          <input
                            type="text"
                            name="tipoDocumento"
                            value="CPF"
                            disabled
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mb-3">
                          <label>Número do Documento:</label>
                          <input
                            type="text"
                            name="numeroDocumento"
                            value={dados.numeroDocumento}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mb-3">
                          <label>Rg:</label>
                          <input
                            type="text"
                            name="Rg"
                            value={dados.Rg}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col mb-3">
                          <label>Tipo de Endereço:</label>
                          <input
                            type="text"
                            name="tipoEndereco"
                            disabled
                            value="Residencial"
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mb-3">
                          <label>Logradouro:</label>
                          <input
                            type="text"
                            name="logradouro"
                            value={dados.logradouro}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mb-3">
                          <label>Número:</label>
                          <input
                            type="text"
                            name="numero"
                            value={dados.numero}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col mb-3">
                          <label>Bairro:</label>
                          <input
                            type="text"
                            name="bairro"
                            value={dados.bairro}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mb-3">
                          <label>Cidade:</label>
                          <input
                            type="text"
                            name="cidade"
                            value={dados.cidade}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="col mb-3">
                          <label>CEP:</label>
                          <InputMask
                            mask="99.999-999"
                            type="text"
                            name="cep"
                            onChange={(e) => {
                              const cep = e.target.value;
                              const cepFormatado = cep.replace(/\D/g, "");
                              if (cepFormatado.length === 8) {
                                fetch(`${apiUrl}/cep/${cepFormatado}`, {
                                  method: "GET",
                                  credentials: "include",
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization:
                                      "Bearer " + Cookies.get("auth_token"),
                                  },
                                }).then((response) => {
                                  if (response.status == 200) {
                                    response.json().then((item) => {
                                      this.setState((prevState) => ({
                                        dados: {
                                          ...prevState.dados, // Mantém os outros campos do objeto `dados`
                                          ["cep"]: item.cep,
                                          ["logradouro"]: item.logradouro,
                                          ["bairro"]: item.bairro,
                                          ["cidade"]: item.localidade,
                                        },
                                      }));
                                    });
                                  }
                                });
                              }
                            }}
                            className="form-control form-control-sm"
                          />
                        </div>

                        <div className="col mb-3">
                          <label>Telefones:</label>
                          <input
                            type="text"
                            name="telefone"
                            value={dados.telefone}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-danger float-left ml-2"
                      onClick={() => {
                        this.props.navigate(-1);
                      }}
                    >
                      Voltar
                    </button>

                    <button
                      type="submit"
                      class="btn btn-primary float-right ml-2"
                      onClick={() => {
                        var dados = this.state.dados;
                        console.log(dados);

                        fetch(
                          `${apiUrl}/${
                            window.location.pathname.split("/")[1]
                          }/editar-solicitante/`,
                          {
                            method: "POST",
                            credentials: "include",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization:
                                "Bearer " + Cookies.get("auth_token"),
                            },
                            body: JSON.stringify(dados),
                          }
                        ).then((response) => {
                          if (response.status == 200) {
                            toast.success("Certificado Criado com sucesso!");

                            response.json().then((data) => {
                              this.props.navigate(`/tjsc`);
                            });
                          }
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} /> Salvar{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default EditSlc;
